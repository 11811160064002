{
  "en": {
    "contact_deals": "Contact & Deals",
    "login_with": "Login with  {provider}",
    "extension_description": "Browser extensions, compatible with Chrome, Brave, Edge",
    "install": "Install",
    "installed": "Installed",
    "not_installed": "Not installed",
    "installing": "Installing",
    "check_again": "Check again",
    "open_provider": "Open {provider}",
    "product_by": "Pipechat is a product by {provider}",
    "faq": "Faq",
    "terms": "Terms",
    "privacy": "Privacy",
    "support": "Support",
    "billing": "Billing",
    "help": "Help",
    "logout": "Logout",
    "templates": "Templates",
    "settings": "Settings",
    "language": "Language",
    "open_conversation": "Open a conversation to start...",
    "group_not_supported": "Group chats are not supported",
    "open_in": "Open in {provider}",
    "add_contact": "Add this contact to {provider}",
    "add_phone": "Or add the number\n{phone}\nto an existing contact",
    "add_username": "Or add the username\n{username}\nto an existing contact",
    "name": "Name",
    "email": "Email",
    "validation.required": "Required",
    "create": "Create",
    "search": "Search",
    "no_results": "No results for \"{term}\"",
    "add_phone_to_contact": "Add {phone}",
    "search_contact": "Search contact...",
    "working_on": "I'm working on...",
    "select_deal": "Select deal...",
    "deal": "Deal",
    "create_deal": "Create new Deal",
    "cancel": "Cancel",
    "deal_title": "Deal title",
    "value": "Value",
    "pipeline": "Pipeline",
    "choose_pipeline": "Select the Pipeline",
    "stage": "Stage",
    "close": "Close",
    "note_from_chat": "Create Note from Chat",
    "select_messages": "Select messages",
    "select_all": "Select All",
    "deselect_all": "Deselect All",
    "create_note": "Create Note",
    "in_contact": "In {name}",
    "in_deal": "In {title}",
    "note_created": "Note created",
    "deal_created": "Deal created",
    "contact_created": "Contact created",
    "now_working_on": "You're now working on {title}",
    "phone_added": "Phone added",
    "telegram_username_added": "Telegram username added",
    "to_something": "To {something}",
    "update_stage": "Update stage for\n{deal_title}",
    "stage_updated": "Stage updated",
    "activities": "Activities",
    "activities_for_deal": "Activities for\n{deal_title}",
    "no_activities": "No activities planned for\n{deal_title}",
    "create_activity": "Plan new activity",
    "filter_by_type": "Filter by type",
    "show_completed": "Show completed",
    "no_activities_found": "No activities",
    "activity_updated": "Activity updated",
    "activity_name": "Activity name",
    "select_date": "Select date",
    "select_time": "Select time",
    "notes": "Notes",
    "activity_create": "Create activity",
    "activity_created": "Activity created",
    "contact": "Contact",
    "activities_for": "Activities for\n{text}",
    "type_notes": "Type notes",
    "notes_type": "Notes",
    "close_deal": "Close\n{title}",
    "won": "Won",
    "lost": "Lost",
    "currently_marked_as": "Currently marked as {status}",
    "deal_closed": "Deal closed",
    "marked_as": "Marked as {status}",
    "reopen": "Reopen",
    "deal_reopened": "Deal reopened",
    "reason": "Reason",
    "other": "Other...",
    "select_template": "Select template",
    "filter_by_tag": "Filter by tag",
    "message_copied": "Message copied",
    "fields_replaced": "You can now paste the text in the message area ({shortcut}).",
    "create_template": "Create new template",
    "edit_template": "Edit template",
    "template_name": "Template name",
    "message": "Message",
    "share_with_team": "Share with my team",
    "add_tag": "Add tag",
    "available_fields": "Available fields",
    "dynamic_fields": "Dynamic fields",
    "field_copied": "Field copied",
    "FULLNAME": "Full name",
    "NAME": "First name",
    "FIRSTNAME": "First name",
    "LASTNAME": "Last name",
    "ORGNAME": "Organization name",
    "can_past_field": "You can paste the field in the message template ({shortcut}).",
    "field_inserted": "Field inserted",
    "template_created": "Template created",
    "template_updated": "Template updated",
    "template_selected": "Template selected",
    "template_deleted": "Template deleted",
    "save": "Save",
    "shared_template": "Template shared by\n{name}",
    "delete": "Delete",
    "confirm": "Confirm",
    "delete_permanently_continue": "Delete permanently?\nThis action cannot be undone.",
    "ok": "Ok",
    "delete_template": "Delete template",
    "no_find_fields": "Couldn't find values for the following fields:",
    "activity_type": "Activity type",
    "edit": "Edit",
    "reload": "Reload",
    "n_seats": "{number} seats",
    "single_user": "Single user",
    "monthly": "Monthly",
    "yearly": "Yearly",
    "month": "Mês",
    "year": "Year",
    "packages": "Packages",
    "add": "Add",
    "bigger_team": "Got a bigger team?",
    "get_in_touch": "Get in touch",
    "with_all_plans": "With all plans you can",
    "can_add_contacts": "Add contacts and phone numbers from WhatsApp Web to your CRM",
    "can_add_messages": "Add messages to deals as a note",
    "can_manage_activities": "Manage your activities",
    "can_create_templates": "Create messages templates",
    "can_update_deals": "Create and update deals",
    "your_active_subscriptions": "Your active subscriptions",
    "your_subscriptions": "Your subscriptions",
    "part_of_these": "You're part of these subscriptions",
    "next_billing": "Next billing",
    "started_on": "Started on",
    "billed": "Billed",
    "non_renewing": "Non-renewing",
    "manage_subscriptions": "Manage subscriptions",
    "subscription_by": "You have a subscription provided by\n{who}",
    "reactivate": "Reactivate",
    "ending_on": "Ending on",
    "active_until": "Active until",
    "manage": "Manage",
    "invoices": "Invoices",
    "billing_info": "Billing info",
    "users": "User | Users",
    "transfer_to_someone": "Transfer it to another user",
    "enabling_you": "This subscription is enabling your own account.",
    "transfer": "Transfer",
    "transfer_to_me": "Transfer to me",
    "subscription_transferred": "Subscription transferred",
    "add_myself": "Add myself",
    "user_enabled": "User enabled",
    "user_removed": "User removed",
    "add_people_in_your_team": "Add people in your team",
    "transfer_to_someone_in_your_team": "Transfer to someone in your team",
    "peers_without_subscription": "People in your team without subscription",
    "choose_plan": "Choose a subscription package",
    "view_plans": "View packages",
    "trial_ends_in": "You have still one day of free trial. | You have still {count} days of free trial.",
    "add_plan": "Add a plan",
    "telegram_handles": "Telegram handles",
    "only_username": "This contact is only displaying a username with no phone number.",
    "choose_person_field": "Please, choose a Person field in {crm} to save Telegram usernames if you'd like to use Pipechat with this contact.",
    "create_person_field": "Create new person field",
    "link_existing_field": "Or link an existing field",
    "not_supported_for_crm": "This feature is not supported for {crm}.",
    "mark_as_completed": "Mark as completed",
    "minutes": "Minutes",
    "seconds": "Seconds",
    "done": "Done",
    "feedback": "Feedback",
    "how_improve": "How can we improve Pipechat?",
    "send": "Send",
    "feedback_sent": "Feedback sent",
    "thank_you": "Thank you 🙂",
    "no_deals_yet": "{name} doesn't have any deals yet.",
    "install_extension_to_use": "Install the Chrome extensions to use this feature.",
    "couldnt_exchange": "Sorry, couldn't complete authentication.\nRedirecting...",
    "couldnt_exchange_clear": "Sorry, couldn't complete authentication.\nPlease, try clearing the cookies following the steps below and try again.",
    "select_number": "Select the correct number",
    "pipechat_for": "Pipechat for {platform}",
    "whatsnew": "See what's new",
    "welcomenew": "Welcome to the new Pipechat",
    "go_to_login": "Go to login",
    "deals_order": "Deals order",
    "deal_title_2": "Title",
    "creation_date": "Creation date",
    "updates_available": "Updates available",
    "update": "Update",
    "maintenance": "Maintenance in progress.\nPlease come back later.",
    "lock": "Lock",
    "unlock": "Unlock",
    "templates_tags_filter_type": "Tags filter view",
    "tags": "Tags",
    "list": "List",
    "click_to_copy": "Click to copy",
    "cached_click_to_sync": "This result is cached for faster access. Click to resync.",
    "account": "Account",
    "click_on_text_to_copy": "Click on the text to copy",
    "quick_copy": "Quick copy",
    "dashboard": "Dashboard",
    "login": "Login",
    "see_whats_new": "See what's new",
    "pipechat_updated": "Pipechat was updated",
    "dismiss": "Dismiss",
    "great": "Great",
    "see_full_changelog": "See full list of changes",
    "latest_updates": "Latest updates",
    "information_for_support": "Information for support",
    "number_detected": "Number detected",
    "show_detected_number": "Show detected number",
    "no_number_detected": "No number detected"
  },
  "pt": {
    "contact_deals": "Contato & Negócios",
    "login_with": "Entrar com {provider}",
    "extension_description": "Extensões de navegador, compatíveis com Chrome, Brave, Edge",
    "install": "Instalar",
    "installed": "Instalado",
    "not_installed": "Não instalado",
    "installing": "Instalando",
    "check_again": "Verificar novamente",
    "open_provider": "Abrir {provider}",
    "product_by": "Pipechat é um produto de {provider}",
    "faq": "Perguntas Frequentes",
    "terms": "Termos",
    "privacy": "Privacidade",
    "support": "Suporte",
    "billing": "Faturamento",
    "help": "Ajuda",
    "logout": "Sair",
    "templates": "Modelos",
    "settings": "Configurações",
    "language": "Idioma",
    "open_conversation": "Abra uma conversa para começar...",
    "group_not_supported": "Grupos não são suportados",
    "open_in": "Abrir em {provider}",
    "add_contact": "Adicione este contato a {provider}",
    "add_phone": "Ou adicione o número\n{phone}\na um contato existente",
    "add_username": "Ou adicione o nome de usuário\n{username}\na um contato existente",
    "name": "Nome",
    "email": "Email",
    "validation.required": "Requerido",
    "create": "Criar",
    "search": "Pesquisar",
    "no_results": "Sem resultados para \"{term}\"",
    "add_phone_to_contact": "Adicione {phone}",
    "search_contact": "Pesquise contato...",
    "working_on": "Estou trabalhando em...",
    "select_deal": "Selecione o negócio...",
    "deal": "Negócio",
    "create_deal": "Criar novo Negócio",
    "cancel": "Cancelar",
    "deal_title": "Título do Negócio",
    "value": "Valor",
    "pipeline": "Pipeline",
    "choose_pipeline": "Selecione a Pipeline",
    "stage": "Etapa",
    "close": "Fechar",
    "note_from_chat": "Criar Nota da Conversa",
    "select_messages": "Selecione mensagens",
    "select_all": "Selec. Tudo",
    "deselect_all": "Deselec. Tudo",
    "create_note": "Criar Nota",
    "in_contact": "Em {name}",
    "in_deal": "Em {title}",
    "note_created": "Nota criada",
    "deal_created": "Negócio criado",
    "contact_created": "Contato criado",
    "now_working_on": "Você está trabalhando em {title}",
    "phone_added": "Telefone adicionado",
    "telegram_username_added": "Nome de usuário do Telegram adicionado",
    "to_something": "Para {something}",
    "update_stage": "Atualizar etapa para\n{deal_title}",
    "stage_updated": "Etapa atualizada",
    "activities": "Atividades",
    "activities_for_deal": "Atividades para\n{deal_title}",
    "no_activities": "Nenhuma atividade planejada para\n{deal_title}",
    "create_activity": "Planejar nova atividade",
    "filter_by_type": "Filtrar por tipo",
    "show_completed": "Mostrar concluídas",
    "no_activities_found": "Nenhuma atividade",
    "activity_updated": "Atividade atualizada",
    "activity_name": "Atividade",
    "select_date": "Selecione a data",
    "select_time": "Selecione a hora",
    "notes": "Notas",
    "activity_create": "Planejar atividade",
    "activity_created": "Atividade planejada",
    "contact": "Contato",
    "activities_for": "Atividades para\n{text}",
    "type_notes": "Escreva notas",
    "notes_type": "Notas",
    "close_deal": "Fechar\n{title}",
    "won": "Ganho",
    "lost": "Perdido",
    "currently_marked_as": "Atualmente marcado como {status}",
    "deal_closed": "Negócio fechado",
    "marked_as": "Marcado como {status}",
    "reopen": "Reabrir",
    "deal_reopened": "Negócio reaberto",
    "reason": "Motivo",
    "other": "Outro...",
    "select_template": "Selecione modelo",
    "filter_by_tag": "Filtrar por tag",
    "message_copied": "Mensagem copiada",
    "fields_replaced": "Você pode colar o texto na área de mensagem ({shortcut}).",
    "create_template": "Criar novo modelo",
    "edit_template": "Editar modelo",
    "template_name": "Nome do modelo",
    "message": "Mensagem",
    "share_with_team": "Compartilhar com equipe",
    "add_tag": "Adicionar tag",
    "available_fields": "Campos disponíveis",
    "dynamic_fields": "Campos dinâmicos",
    "field_copied": "Campo copiado",
    "FULLNAME": "Nome completo",
    "NAME": "Primeiro nome",
    "FIRSTNAME": "Primeiro nome",
    "LASTNAME": "Sobrenome",
    "ORGNAME": "Organização",
    "can_past_field": "Você pode colar o campo no modelo de mensagem ({shortcut}).",
    "field_inserted": "Campo inserido",
    "template_created": "Modelo criado",
    "template_updated": "Modelo atualizado",
    "template_selected": "Modelo selecionado",
    "template_deleted": "Modelo excluído",
    "save": "Salvar",
    "shared_template": "Modelo compartilhado por\n{name}",
    "delete": "Excluir",
    "confirm": "Confirmar",
    "delete_permanently_continue": "Excluir permanentemente?\nEsta ação não pode ser desfeita.",
    "ok": "Ok",
    "delete_template": "Excluir modelo",
    "no_find_fields": "Não foi possível encontrar valores para os seguintes campos:",
    "activity_type": "Tipo de atividade",
    "edit": "Editar",
    "reload": "Recarregar",
    "n_seats": "{number} usuários",
    "single_user": "Usuário único",
    "monthly": "Mensal",
    "yearly": "Anual",
    "month": "Mês",
    "year": "Ano",
    "packages": "Pacotes",
    "add": "Adicionar",
    "bigger_team": "Tem uma equipe maior?",
    "get_in_touch": "Fale conosco",
    "with_all_plans": "Com todos os planos você pode",
    "can_add_contacts": "Adicionar contatos e números de telefone do WhatsApp Web ao seu CRM",
    "can_add_messages": "Adicionar mensagens aos negócios como uma nota",
    "can_manage_activities": "Planejar suas atividades",
    "can_create_templates": "Criar modelos de mensagens",
    "can_update_deals": "Criar e atualizar negócios",
    "your_active_subscriptions": "Suas assinaturas ativas",
    "your_subscriptions": "Suas assinaturas",
    "part_of_these": "Você faz parte dessas assinaturas",
    "next_billing": "Próximo pagamento",
    "started_on": "Iniciado em",
    "billed": "Faturado",
    "non_renewing": "Não renovável",
    "manage_subscriptions": "Administrar assinaturas",
    "subscription_by": "Você tem uma assinatura fornecida por\n{who}",
    "reactivate": "Reativar",
    "ending_on": "Terminando em",
    "active_until": "Ativo até",
    "manage": "Administrar",
    "invoices": "Faturas",
    "billing_info": "Informações de faturamento",
    "users": "Usuário | Usuários",
    "transfer_to_someone": "Transferir para outro usuário",
    "enabling_you": "Esta assinatura está habilitando sua própria conta.",
    "transfer": "Transferir",
    "transfer_to_me": "Transferir para mim",
    "subscription_transferred": "Assinatura transferida",
    "add_myself": "Adicionar a mim mesmo",
    "user_enabled": "Usuário habilitado",
    "user_removed": "Usuário removido",
    "add_people_in_your_team": "Adicione pessoas à sua equipe",
    "transfer_to_someone_in_your_team": "Transfira para alguém na sua equipe",
    "peers_without_subscription": "Pessoas em sua equipe sem assinatura",
    "choose_plan": "Escolha um pacote de assinatura",
    "view_plans": "Ver pacotes",
    "trial_ends_in": "Você ainda tem um dia de teste gratuito. | Você ainda tem {count} dias de teste gratuito.",
    "add_plan": "Adicionar um plano",
    "telegram_handles": "Usuários do Telegram",
    "only_username": "Este contato está exibindo apenas um nome de usuário sem número de telefone.",
    "choose_person_field": "Por favor, escolha um campo Pessoa no {crm} para salvar os nomes de usuário do Telegram se você quiser usar o Pipechat com este contato.",
    "create_person_field": "Criar novo campo de pessoa",
    "link_existing_field": "Ou vincule um campo existente.",
    "not_supported_for_crm": "Este recurso não é suportado para {crm}.",
    "mark_as_completed": "Marcar como concluído",
    "minutes": "Minutos",
    "seconds": "Segundos",
    "done": "Concluído",
    "feedback": "Sugestão",
    "how_improve": "Como podemos melhorar o Pipechat?",
    "send": "Enviar",
    "feedback_sent": "Feedback enviado",
    "thank_you": "Obrigado 🙂",
    "no_deals_yet": "{name} ainda não tem negócios.",
    "install_extension_to_use": "Instale as extensões do Chrome para usar este recurso.",
    "couldnt_exchange": "Desculpe, não foi possível concluir a autenticação.\nRedirecionando...",
    "couldnt_exchange_clear": "Desculpe, não foi possível concluir a autenticação.\nPor favor, tente limpar os cookies seguindo os passos abaixo e tente novamente.",
    "select_number": "Selecione o número correto",
    "pipechat_for": "Pipechat para {platform}",
    "whatsnew": "Ver o que há de novo",
    "welcomenew": "Seja bem-vindo ao novo Pipechat",
    "go_to_login": "Ir para o login",
    "deals_order": "Ordem dos negócios",
    "deal_title_2": "Título",
    "creation_date": "Data de criação",
    "updates_available": "Atualizações disponíveis",
    "update": "Atualizar",
    "maintenance": "Manutenção em andamento.\nPor favor, volte mais tarde.",
    "lock": "Bloquear",
    "unlock": "Desbloquear",
    "templates_tags_filter_type": "Visualização de filtro de tags",
    "tags": "Tags",
    "list": "Lista",
    "click_to_copy": "Clique para copiar",
    "cached_click_to_sync": "Este resultado está em cache para acesso mais rápido. Clique para ressincronizar.",
    "account": "Conta",
    "click_on_text_to_copy": "Clique no texto para copiar",
    "quick_copy": "Cópia rápida",
    "dashboard": "Painel",
    "login": "Login",
    "see_whats_new": "Ver o que há de novo",
    "pipechat_updated": "Pipechat foi atualizado",
    "dismiss": "Dispensar",
    "great": "Ótimo",
    "see_full_changelog": "Ver lista completa de mudanças",
    "latest_updates": "Últimas atualizações",
    "information_for_support": "Informações para suporte",
    "number_detected": "Número detectado",
    "show_detected_number": "Mostrar número detectado",
    "no_number_detected": "Nenhum número detectado"
  },
  "es": {
    "contact_deals": "Contacto & Negocios",
    "login_with": "Iniciar sesión con {provider}",
    "extension_description": "Extensiones de navegador, compatibles con Chrome, Brave, Edge",
    "install": "Instalar",
    "installed": "Instalado",
    "not_installed": "No instalado",
    "installing": "Instalando",
    "check_again": "Verificar de nuevo",
    "open_provider": "Abrir {provider}",
    "product_by": "Pipechat es un producto de {provider}",
    "faq": "Preguntas Frecuentes",
    "terms": "Términos",
    "privacy": "Privacidad",
    "support": "Soporte",
    "billing": "Facturación",
    "help": "Ayuda",
    "logout": "Cerrar sesión",
    "templates": "Plantillas",
    "settings": "Ajustes",
    "language": "Idioma",
    "open_conversation": "Abre una conversación para empezar...",
    "group_not_supported": "Los chats grupales no están soportados",
    "open_in": "Abrir en {provider}",
    "add_contact": "Añadir este contacto a {provider}",
    "add_phone": "O añade el número\n{phone}\na un contacto existente",
    "add_username": "O añade el nombre de usuario\n{username}\na un contacto existente",
    "name": "Nombre",
    "email": "Correo electrónico",
    "validation.required": "Requerido",
    "create": "Crear",
    "search": "Buscar",
    "no_results": "No hay resultados para \"{term}\"",
    "add_phone_to_contact": "Añadir {phone}",
    "search_contact": "Buscar contacto...",
    "working_on": "Estoy trabajando en...",
    "select_deal": "Seleccionar trato...",
    "deal": "Trato",
    "create_deal": "Crear nuevo trato",
    "cancel": "Cancelar",
    "deal_title": "Título del trato",
    "value": "Valor",
    "pipeline": "Pipeline",
    "choose_pipeline": "Selecciona el Pipeline",
    "stage": "Etapa",
    "close": "Cerrar",
    "note_from_chat": "Crear nota desde el chat",
    "select_messages": "Seleccionar mensajes",
    "select_all": "Seleccionar todo",
    "deselect_all": "Deselec. todo",
    "create_note": "Crear nota",
    "in_contact": "En {name}",
    "in_deal": "En {title}",
    "note_created": "Nota creada",
    "deal_created": "Trato creado",
    "contact_created": "Contacto creado",
    "now_working_on": "Ahora estás trabajando en {title}",
    "phone_added": "Teléfono añadido",
    "telegram_username_added": "Nombre de usuario de Telegram añadido",
    "to_something": "A {something}",
    "update_stage": "Actualizar etapa para\n{deal_title}",
    "stage_updated": "Etapa actualizada",
    "activities": "Actividades",
    "activities_for_deal": "Actividades para\n{deal_title}",
    "no_activities": "No hay actividades planeadas para\n{deal_title}",
    "create_activity": "Planear nueva actividad",
    "filter_by_type": "Filtrar por tipo",
    "show_completed": "Mostrar completadas",
    "no_activities_found": "No hay actividades",
    "activity_updated": "Actividad actualizada",
    "activity_name": "Nombre de la actividad",
    "select_date": "Seleccionar fecha",
    "select_time": "Seleccionar hora",
    "notes": "Notas",
    "activity_create": "Crear actividad",
    "activity_created": "Actividad creada",
    "contact": "Contacto",
    "activities_for": "Actividades para\n{text}",
    "type_notes": "Escribir notas",
    "notes_type": "Notas",
    "close_deal": "Cerrar\n{title}",
    "won": "Ganado",
    "lost": "Perdido",
    "currently_marked_as": "Actualmente marcado como {status}",
    "deal_closed": "Trato cerrado",
    "marked_as": "Marcado como {status}",
    "reopen": "Reabrir",
    "deal_reopened": "Trato reabierto",
    "reason": "Motivo",
    "other": "Otro...",
    "select_template": "Seleccionar plantilla",
    "filter_by_tag": "Filtrar por etiqueta",
    "message_copied": "Mensaje copiado",
    "fields_replaced": "Ahora puedes pegar el texto en el área de mensajes ({shortcut}).",
    "create_template": "Crear nueva plantilla",
    "edit_template": "Editar plantilla",
    "template_name": "Nombre de la plantilla",
    "message": "Mensaje",
    "share_with_team": "Compartir con mi equipo",
    "add_tag": "Añadir etiqueta",
    "available_fields": "Campos disponibles",
    "dynamic_fields": "Campos dinámicos",
    "field_copied": "Campo copiado",
    "FULLNAME": "Nombre completo",
    "NAME": "Nombre",
    "FIRSTNAME": "Nombre",
    "LASTNAME": "Apellido",
    "ORGNAME": "Nombre de la organización",
    "can_past_field": "Puedes pegar el campo en la plantilla de mensajes ({shortcut}).",
    "field_inserted": "Campo insertado",
    "template_created": "Plantilla creada",
    "template_updated": "Plantilla actualizada",
    "template_selected": "Plantilla seleccionada",
    "template_deleted": "Plantilla eliminada",
    "save": "Guardar",
    "shared_template": "Plantilla compartida por\n{name}",
    "delete": "Eliminar",
    "confirm": "Confirmar",
    "delete_permanently_continue": "¿Eliminar permanentemente?\nEsta acción no se puede deshacer.",
    "ok": "Ok",
    "delete_template": "Eliminar plantilla",
    "no_find_fields": "No se pudieron encontrar valores para los siguientes campos:",
    "activity_type": "Tipo de actividad",
    "edit": "Editar",
    "reload": "Recargar",
    "n_seats": "{number} usuarios",
    "single_user": "Usuario único",
    "monthly": "Mensual",
    "yearly": "Anual",
    "month": "Mes",
    "year": "Año",
    "packages": "Planes",
    "add": "Añadir",
    "bigger_team": "¿Tienes un equipo más grande?",
    "get_in_touch": "Ponte en contacto",
    "with_all_plans": "Con todos los planes puedes",
    "can_add_contacts": "Agregar contactos y números de teléfono de WhatsApp Web a tu CRM",
    "can_add_messages": "Agregar mensajes a los tratos como una nota",
    "can_manage_activities": "Planear tus actividades",
    "can_create_templates": "Crear plantillas de mensajes",
    "can_update_deals": "Crear y actualizar tratos",
    "your_active_subscriptions": "Tus suscripciones activas",
    "your_subscriptions": "Tus suscripciones",
    "part_of_these": "Eres parte de estas suscripciones",
    "next_billing": "Próximo pago",
    "started_on": "Iniciado en",
    "billed": "Facturado",
    "non_renewing": "No renovable",
    "manage_subscriptions": "Administrar suscripciones",
    "subscription_by": "Tienes una suscripción proporcionada por\n{who}",
    "reactivate": "Reactivar",
    "ending_on": "Terminando en",
    "active_until": "Activo hasta",
    "manage": "Administrar",
    "invoices": "Facturas",
    "billing_info": "Información de facturación",
    "users": "Usuario | Usuarios",
    "transfer_to_someone": "Transferir a otro usuario",
    "enabling_you": "Esta suscripción está habilitando tu propia cuenta.",
    "transfer": "Transferir",
    "transfer_to_me": "Transferir a mí",
    "subscription_transferred": "Suscripción transferida",
    "add_myself": "Añadirme",
    "user_enabled": "Usuario habilitado",
    "user_removed": "Usuario eliminado",
    "add_people_in_your_team": "Añade personas a tu equipo",
    "transfer_to_someone_in_your_team": "Transfiere a alguien en tu equipo",
    "peers_without_subscription": "Personas en tu equipo sin suscripción",
    "choose_plan": "Elija un paquete de suscripción",
    "view_plans": "Ver planes",
    "trial_ends_in": "Todavía tiene un día de prueba gratuita. | Todavía tiene {count} días de prueba gratuita.",
    "add_plan": "Añadir un plan",
    "telegram_handles": "Usuarios de Telegram",
    "only_username": "Este contacto está mostrando solo un nombre de usuario sin número de teléfono.",
    "choose_person_field": "Por favor, elija un campo de Persona en {crm} para guardar los nombres de usuario de Telegram si desea usar Pipechat con este contacto.",
    "create_person_field": "Crear nuevo campo de persona",
    "link_existing_field": "O vincula un campo existente.",
    "not_supported_for_crm": "Esta función no es compatible con {crm}.",
    "mark_as_completed": "Marcar como completado",
    "minutes": "Minutos",
    "seconds": "Segundos",
    "done": "Hecho",
    "feedback": "Comentarios",
    "how_improve": "¿Cómo podemos mejorar Pipechat?",
    "send": "Enviar",
    "feedback_sent": "Comentarios enviados",
    "thank_you": "Gracias 🙂",
    "no_deals_yet": "{name} aún no tiene tratos.",
    "install_extension_to_use": "Instala las extensiones de Chrome para usar esta función.",
    "couldnt_exchange": "Lo siento, no se pudo completar la autenticación.\nRedirigiendo...",
    "couldnt_exchange_clear": "Lo siento, no se pudo completar la autenticación.\nPor favor, intente borrar las cookies siguiendo los pasos a continuación y vuelva a intentarlo.",
    "select_number": "Selecciona el número correcto",
    "pipechat_for": "Pipechat para {platform}",
    "whatsnew": "Ver qué hay de nuevo",
    "welcomenew": "Bienvenido al nuevo Pipechat",
    "go_to_login": "Ir al login",
    "deals_order": "Orden de tratos",
    "deal_title_2": "Título",
    "creation_date": "Fecha de creación",
    "updates_available": "Actualizaciones disponibles",
    "update": "Actualizar",
    "maintenance": "Mantenimiento en progreso.\nPor favor, vuelve más tarde.",
    "lock": "Bloquear",
    "unlock": "Desbloquear",
    "templates_tags_filter_type": "Vista de filtro de etiquetas",
    "tags": "Etiquetas",
    "list": "Lista",
    "click_to_copy": "Haga clic para copiar",
    "cached_click_to_sync": "Este resultado está en caché para un acceso más rápido. Haga clic para resincronizar.",
    "account": "Cuenta",
    "click_on_text_to_copy": "Haga clic en el texto para copiar",
    "quick_copy": "Copia rápida",
    "dashboard": "Tablero",
    "login": "Login",
    "see_whats_new": "Ver qué hay de nuevo",
    "pipechat_updated": "Pipechat se actualizó",
    "dismiss": "Descartar",
    "great": "Genial",
    "see_full_changelog": "Ver lista completa de cambios",
    "latest_updates": "Últimas actualizaciones",
    "information_for_support": "Información para soporte",
    "number_detected": "Número detectado",
    "show_detected_number": "Mostrar número detectado",
    "no_number_detected": "Ningún número detectado"
  }
}